// Colores primarios
$color-primario-verde: #006368;
$color-primario-azul: #008ca1;
$color-primario-negro: #1a2127;
$color-primario-blanco: #fff;
$color-primario-gris: #495057;
$color-primario-negro: #1d242b;

// Colores secundarios
// $color-secundario-morado: #812179;
$color-secundario-naranja: #ff6051;
$color-secundario-amarillo: #ff9900;
$color-secundario-marron: #d7cdb6;
$color-secundario-verde: #00a774;
$color-secundario-verde-oscuro: #003546;
$color-secundario-marron-raro: #6f6974;
$color-secundario-azul-oscuro: #212529;
$color-secundario-azul-marino: #232f34;

// Colores lavados
$color-lavado-gris: #f0f6f7;
$color-lavado-gris-oscuro: #d1dcdf;
$color-lavado-marron-oscuro: #bbb4b6;
$color-border-grey: #a7a7a7;
$color-icon-filter-datatble: #2e6c73;
$color-grey: #cbc9d5;

// Color de texto e iconos
// $color-texto-iconos: #a7a7a7;

// Color cancelar
$color-cancelar: #e41e1e;
$color-cancelar-hover: #820b0b;
