footer.footer {
  background: $footer-background;
  text-align: center;
  border-top: 1px solid $footer-border-top-color;
  padding: $spacer $spacer * 0.75;
  direction: ltr;

  ul {
    margin-bottom: 0;
  }
  p.first {
    margin: 25px 0 0 0 !important;
  }

  @include media-breakpoint-down(md) {
    width: 100vw;
  }
}
