.pt-10px {
    padding-top: 10px !important;
}
.pb-10px {
    padding-bottom: 10px !important;
}
.pt-20px {
    padding-top: 20px !important;
}
.pb-20px {
    padding-bottom: 20px !important;
}
.pt-30px {
    padding-top: 30px !important;
}
.pb-30px {
    padding-bottom: 30px !important;
}
.pt-50px {
    padding-top: 50px !important;
}
.pb-50px {
    padding-bottom: 50px !important;
}

.mt-10px {
    margin-top: 10px !important;
}
.mb-10px {
    margin-bottom: 10px !important;
}
.mt-20px {
    margin-top: 20px !important;
}
.mb-20px {
    margin-bottom: 20px !important;
}
.mt-30px {
    margin-top: 30px !important;
}
.mb-30px {
    margin-bottom: 30px !important;
}
.mt-50px {
    margin-top: 50px !important;
}
.mb-50px {
    margin-bottom: 50px !important;
}

.pl-0 {
    padding-left: 0 !important;
}
.pl-1 {
    padding-left: 0.25rem !important;
}
.pl-2 {
    padding-left: 0.5rem !important;
}
.pl-3 {
    padding-left: 1rem !important;
}
.pl-4 {
    padding-left: 1.5rem !important;
}
.pl-5 {
    padding-left: 3rem !important;
}

.pr-0 {
    padding-right: 0 !important;
}
.pr-1 {
    padding-right: 0.25rem !important;
}
.pr-2 {
    padding-right: 0.5rem !important;
}
.pr-3 {
    padding-right: 1rem !important;
}
.pr-4 {
    padding-right: 1.5rem !important;
}
.pr-5 {
    padding-right: 3rem !important;
}

.ml-0 {
    margin-left: 0 !important;
}
.ml-1 {
    margin-left: 0.25rem !important;
}
.ml-2 {
    margin-left: 0.5rem !important;
}
.ml-3 {
    margin-left: 1rem !important;
}
.ml-4 {
    margin-left: 1.5rem !important;
}
.ml-5 {
    margin-left: 3rem !important;
}

.mr-0 {
    margin-right: 0 !important;
}
.mr-1 {
    margin-right: 0.25rem !important;
}
.mr-2 {
    margin-right: 0.5rem !important;
}
.mr-3 {
    margin-right: 1rem !important;
}
.mr-4 {
    margin-right: 1.5rem !important;
}
.mr-5 {
    margin-right: 3rem !important;
}
