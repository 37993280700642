.bootstrap-datetimepicker-widget {
  .table th, .table td {
    border: 0;
  }

  table th,
  table td,
  table td.day {
    height: 36px;
    line-height: 36px;
    width: 36px;
  }
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  color: $static-white;
}