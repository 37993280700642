@import './assets/fonts/Poppins/stylesheet.css';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';

@import 'util-resolution.scss';
@import 'style/colors.scss';
@import 'style/template-fixes.scss';
@import 'style/customize-bootstrap.scss'; // Para modificar clases que no están en la pantilla
@import 'style/template/light.scss'; // importamos la plantilla con estilo light
@import 'style/datatable/datatable.scss';
@import 'style/customize-indigo-pink.scss';
@import 'style/customize-pagination.scss';
@import 'style/customize-forms.scss';
@import '~@circlon/angular-tree-component/css/angular-tree-component.css'; // import for tree nodes

// Elements html
html,
body {
  height: 100%;
  font-family: 'poppinsregular', Roboto, 'Helvetica Neue', sans-serif !important;
  color: $color-primario-gris;
}
.custom-tooltip {
  background-color: $color-secundario-azul-marino;
  color: white;
  padding: 12px;
  border-radius: 8px;
  &:before {
    box-shadow: rgb(216, 212, 212) 0.0625rem -0.0625rem 0px 0px;
    content: '';
    display: block;
    height: 0.75rem;
    left: 50%;
    margin-top: -0.4rem;
    position: absolute;
    top: 100%;
    transform: translateX(-50%) rotate(135deg);
    width: 0.75rem;
    z-index: 1;
    background: $color-secundario-azul-marino;
    border-width: 0.125rem;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
  }
}

body {
  overflow-y: unset;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'poppinsregular', Roboto, 'Helvetica Neue', sans-serif;
  background-color: $color-lavado-gris;
  color: $color-primario-gris;
}

a {
  text-decoration: none;
  color: $color-primario-gris;
  font-weight: 600;

  &:hover {
    color: $color-primario-verde;
    text-decoration: none;
  }
}

button {
  background-color: transparent;
  outline: none !important;
  border: none;
  color: $color-primario-gris;
}

h1,
.mat-typography h1 {
  font-size: 20px;
  line-height: 39px;
  font-weight: 500;
}

h2,
.mat-typography h2 {
  font-size: 18px;
  line-height: 34px;
  font-weight: 500;
}

h3,
.mat-typography h3 {
  font-size: 16px;
  line-height: 27px;
  font-weight: 500;
}

h4,
.mat-typography h4 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

// Son las cifras en los cuadros de analytics y en el detalle de merchant
h5,
.mat-typography h5 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 600;
}

th {
  text-transform: uppercase;
  font-weight: 600 !important;
}

td {
  border: none !important;
}

label.upload {
  padding: 5px 10px;
  background: $color-primario-verde;
  color: $color-primario-blanco;
  width: 110px;

  &:hover {
    cursor: pointer;
    color: $color-primario-blanco;
  }
}

// Estilos popups
.mat-dialog-container {
  .mat-dialog-title {
    font-weight: bold;
  }
  .mat-dialog-content {
    display: block;
    overflow: auto;
    margin: 0 !important;
    .container {
      padding: 0;
    }
  }
  .mat-dialog-actions {
    margin-bottom: 0 !important;
    min-height: auto !important;
  }
}

// Label del input (placeholder)
.mat-form-field-appearance-legacy .mat-form-field-label {
  padding: 8px 4px 2px 10px;
}

// Subrayado del input (border-bottom)
.mat-form-field-appearance-legacy .mat-form-field-underline {
  // height: 1px;
  display: none;
}

// Cuando no se rellena el input y se ha dado click (invalido)
.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  // caret-color: #f44336;
  caret-color: $color-secundario-naranja;
}

.mat-form-field-infix {
  border-top: none;
}

.mat-form-field-label-wrapper {
  display: none !important;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding-top: 0 !important;
}

.dataTables_length {
  display: none !important;
}

// Clases botones
.btn-primary--active {
  background-color: $color-primario-verde;
  color: $color-primario-blanco;
  width: 170px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background-color: $color-secundario-verde-oscuro;
    color: $color-primario-blanco;
  }
  @include inMobileResolution {
    width: 100%;
  }
}

.btn-primary--inactive {
  background-color: $color-lavado-marron-oscuro;
  color: $color-primario-blanco;
  pointer-events: none;
  opacity: 0.65;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  @include inMobileResolution {
    width: 100%;
  }
}

.btn-secondary--active {
  background-color: $color-primario-blanco;
  color: $color-primario-negro;
  border: 2px solid $color-primario-negro;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background-color: $color-primario-negro;
    color: $color-primario-blanco;
  }
  @include inMobileResolution {
    width: 100%;
  }
}

.btn-secondary--inactive {
  background-color: $color-primario-blanco;
  color: $color-lavado-marron-oscuro;
  border: 2px solid $color-lavado-marron-oscuro;
  pointer-events: none;
  opacity: 0.65;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  @include inMobileResolution {
    width: 100%;
  }
}

.btn-cancel--active {
  background-color: $color-cancelar;
  color: $color-primario-blanco;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;

  &:hover {
    background-color: $color-cancelar-hover;
    cursor: pointer;
    color: $color-primario-blanco;
  }
  @include inMobileResolution {
    width: 100%;
  }
}

.btn-cancel--inactive {
  background-color: $color-lavado-marron-oscuro;
  color: $color-primario-blanco;
  pointer-events: none;
  opacity: 0.65;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  @include inMobileResolution {
    width: 100%;
  }
}

.btn-danger--active {
  background-color: $color-cancelar;
  color: $color-primario-blanco;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background-color: $color-cancelar-hover;
    color: $color-primario-blanco;
  }
  @include inMobileResolution {
    width: 100%;
  }
}

.btn-outline-primary {
  background-color: $color-primario-blanco;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  @include inMobileResolution {
    width: 100%;
  }
}

.icon-idiom {
  width: 24px;
  height: 24px;
}

.icon-idiom--active {
  border: 2px solid black;
}

.icon-idiom--spacing {
  margin-right: 8px;
}

.hr {
  background-color: $color-lavado-gris-oscuro;
  margin-bottom: 10px;
  height: 2px;
}

.hr-light {
  background-color: $color-lavado-gris-oscuro;
  margin-bottom: 10px;
  height: 1px;
}

.timeline-item:before {
  border: 3px solid $color-primario-verde;
}

.font-bold {
  font-weight: bold !important;
}
.font-medium {
  font-size: medium;
}
.default-font-color {
  color: $color-primario-gris !important;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.margin-0-auto {
  margin: 0 auto !important;
}

.gap-10 > * {
  margin: 0 10px;
}

.logo-size {
  width: 200px !important;
  height: 200px !important;
}

.img-cover {
  object-fit: cover;
  img {
    object-fit: contain;
  }
}
.img-cover-product {
  object-fit: cover;
  img {
    object-fit: cover;
  }
}

// breadcrumbs
.breadcrumb {
  margin-bottom: 25px;
  .breadcrumb-item {
    a {
      font-weight: normal;
    }
  }
}

//img
img.rounded-circle {
  object-fit: contain;
  background-color: $color-primario-blanco;
}

// change sidebarcolor
.sidebar > .sidebar-content {
  background-color: $color-primario-negro !important;
}

// custom table filter and pagination
dashboard-custom-datatable {
  .filter-button {
    .filter-icon {
      background-color: $color-icon-filter-datatble !important;
      color: $color-primario-blanco !important;
    }
  }
  .dataTables_wrapper .dataTables_paginate {
    .paginate_button.current {
      background: $color-icon-filter-datatble !important;
      color: $color-primario-blanco !important;
      border: 1px $color-icon-filter-datatble solid !important;
    }
  }
}

// slider km in locations
dashboard-card-location-service {
  .mat-slider-thumb {
    transform: scale(0) !important;
  }
  .mat-slider-horizontal {
    .mat-slider-thumb-label {
      top: -55px !important;
      right: -22px !important;
      transform: rotate(45deg) !important;
      border-radius: 50% 50% 0 !important;
      background-color: $color-primario-verde !important;
      background-color: $color-primario-verde !important;
      text-align: center !important;
      width: 48px !important;
      height: 48px !important;
      .mat-slider-thumb-label-text {
        opacity: 1 !important;
      }
    }
    .mat-slider-ticks-container {
      background-color: $color-primario-verde !important;
      height: 4px !important;
    }
  }
}
