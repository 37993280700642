/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 4, 2020 */



@font-face {
    font-family: 'poppinsregular';
    src: url('poppins-regular-webfont.eot');
    src: url('poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('poppins-regular-webfont.woff2') format('woff2'),
         url('poppins-regular-webfont.woff') format('woff'),
         url('poppins-regular-webfont.ttf') format('truetype'),
         url('poppins-regular-webfont.svg#poppinsregular') format('svg');
    font-weight: normal;
    font-style: normal;

}